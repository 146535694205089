<template>
  <v-app>
    <dashboard-core-app-bar />
    <dashboard-core-drawer :hideMiniToggle="true" />
    <dashboard-core-view />
    <base-snack-bars :objects.sync="snackBars" />
     <Footer />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',
  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View'),
    Footer: () => import('./components/Footer'),
  },
  data: () => ({
    drawer: null
  }),
}
</script>

<style>

</style>